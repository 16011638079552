.combination-planner, .planner
    display: grid
    grid-template-columns: auto 5fr
    gap: 10px
    &.bottom-detail
        display: block
    &.standalone
        .calendar__content
            max-height: calc(100vh - 120px)
            height: calc(100vh - 120px)
    &__attachments
        height: auto
        max-height: 100vh
        min-width: 450px
        width: 30vw
        max-width: 35vw
        &.standalone
            width: 100%
            max-width: 100%
            .planBoard, .planner, .watchtower
                &__search-results
                    max-height: calc(100vh - 100px)
                    height: calc(100vh - 100px)

.map-planner
    z-index: 0
    position: relative
    &__filters, .layer-selector
        z-index: 1000
        position: absolute
    &__filters
        width: fit-content
        display: flex
        left: 10px
        top: 5px
        .switch-wrapper
            margin: 0 10px
            flex-direction: column
            button
                border: none
                font-size: $fontSizeS
                padding: 5px 10px
                border-radius: $radiusS
                line-height: normal
                transition: all 0.2s ease-in
                &.enabled
                    color: $white
                    background: $primary
                    svg
                        fill: $white
                &.disabled
                    background: $llightgray
                    color: $darkgray
                    svg
                        fill: $darkgray
                &:hover
                    color: $primary
                    background: color-mix(in srgb, $primary 20%, white)
                    svg
                        fill: $primary
                svg
                    width: 20px
                    height: 16px
                    transform: translateY(4px)
                    transition: all 0.2s ease-in

        .action
            background: $white
            border-radius: $roundRadius
    &__search
        width: 250px
        position: relative
    .action, &__search
        box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.3)

.header
    &__view-switcher
        display: flex
        align-items: center

.view-switcher
    &__btn-left, &__btn-right, &__btn-middle
        width: 100px
        background: $lightgray
        text-align: center
        height: 35px
        line-height: 35px
        font-size: $fontSizeM
        cursor: pointer

        &.active
            background: $primary
            color: $white

        &:hover
            background: $primary
            color: $white

    &__btn-middle
        border-radius: 0
    &__btn-left
        border-radius: $radiusL 0 0 $radiusL
    &__btn-right
        border-radius: 0 $radiusL $radiusL 0

.header
    &__date-switcher
        background-color: $llightgray
        display: flex
        gap: 15px
        align-items: center
        border-radius: $radiusL
        height: 35px

.date-switcher
    &__btn-left, &__btn-right
        background-color: $primary
        border-radius: $roundRadius
        height: 35px
        width: 35px
        text-align: center

        svg
            width: 22px
            height: 35px
            stroke: $white

    &__btn-left svg
        rotate: 90deg

    &__btn-right svg
        rotate: 270deg

    &__btn-center
        font-size: $fontSizeM
        padding: 8px 20px
        border-radius: $radiusL
        cursor: pointer

        &:hover
            background: $primary
            color: $white

// calendar body ----------------------
.calendar
    position: relative
    overflow: hidden
    height: auto
    &__header
        display: flex
        justify-content: space-between
        align-items: center
        margin-right: 10px
        padding: 0 0 10px 0
        margin-top: 10px
        flex-wrap: wrap
        background: $white
        .header__current-date
            text-transform: capitalize
    &__navigate-right
        height: 100vh
        background-image: linear-gradient(91deg, rgba(255, 255, 255, 0), $white 100%)
        position: absolute
        opacity: 0.9
        right: 0
        z-index: 10
        width: 40px
        filter: blur(2px)

    &__content
        overflow: scroll

    &__header-lane, &__body-lane
        display: flex
        justify-content: space-between
        margin-top: 5px
        gap: 1px
        background: $white
        &.next-day-view
            .header-lane, .body-lane
                left: -200px

        .header-lane, .body-lane
            &__content-box
                min-height: 40px
                background-color: $llightgray
                border-radius: $radiusS
                color: $darkgray
                font-size: $fontSizeM
                flex-grow: 1
                flex-shrink: 1
                flex-basis: 0
                position: relative
                align-items: center
                &--small
                    min-height: 30px
                &:first-child
                    padding: 0 10px
                &.active-dragging
                    box-sizing: border-box
                    border: 1px dashed $gray
                    overflow: visible
                &:hover, &:focus
                    background-color: $lightgray

                &:hover .plus
                    display: flex
                &:-moz-drag-over
                    background-color: $lightgray
                &.vehicle
                    z-index: 15
                .plus
                    position: absolute
                    width: 100%
                    height: 100%
                    align-items: center
                    display: none
                    justify-content: center
                    svg
                        width: 30px
                        height: 30px
                        stroke: $primary

            &__information-box
                padding: 10px

        .header-lane
            &__content-box
                min-height: 0px
                padding: 5px 2px
                font-weight: bold
                background-color: $lightgray
                font-size: $fontSizeM
                &.small
                    font-size: $fontSizeS
                &.active
                    background-color: $primary !important
                    color: $white

    &.static-left
        .header-lane, .body-lane
            &__content-box:nth-of-type(1)
                left: 0
                z-index: 15
                width: 120px
                min-width: 200px
                max-width: 200px
                position: sticky
                padding: 5px 10px
                margin-right: 5px
                border-right: 2px dashed $gray
                border-radius: $radiusS 0 0 5px
                background-image: linear-gradient(91deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 98%)
    &__header-lane
        z-index: 50
        position: sticky
        top: 0

.body-lane__content-box, .header-lane__content-box
    position: relative
    .time_indicator
        border-right: 2px dashed rgba(255, 0, 0, 0.55)
        height: 100%
        width: 1px
        position: absolute
        left: 0
        top: 0

// Attachment -----------------------------
.attachment
    min-height: 60px

    &__not-found
        font-size: $fontSizeM
        color: $gray
        font-style: italic
        text-align: center
        padding: 20px 0

    &__card
        gap: 5px
        width: 100%
        padding: 5px
        display: flex
        border-radius: $radiusS
        align-items: center
        justify-content: space-between
        margin-top: 8px

        &.equipments, &.chauffeurs-search, &.vehicles
            background-color: $white
            color: $darkgray
            &:hover
                background-color: $primary
                color: $white

    &__name
        font-size: $fontSizeS
        font-weight: lighter

        &.responsive
            display: none

    &__content
        display: flex
        align-items: center
        gap: 8px

    &__image
        height: 22px
        width: 22px
        text-align: center
        svg
            height: 22px
            width: 16px
            fill: $darkgray
    &__detach
        height: 22px
        width: 22px
        text-align: center
        svg
            height: 22px
            width: 9px
    &__image
        border-radius: $radiusS
        background-color: $white
    &__actions
        display: none
        transition: 0.1s
    &__detach
        background-color: #be3535
        border-radius: $radiusL
        svg
            fill: $white

.attachment__card:hover .attachment__actions
    display: block

// search consignments

.consignment
    &__search-card
        background-color: $llightgray
        border-radius: $radiusS
        margin-top: 5px
        position: relative
        font-size: $fontSizeM
        .status
            right: 5px
            display: flex
            gap: 5px
            align-self: center
            .type, .substatus
                font-size: $fontSizeS
                padding: 2px 7px
                font-weight: bold

            .combine
                color: $white
                border-radius: $radiusL
                background-color: $lightgray
                width: 30px
                height: 20px
                text-align: center
                svg
                    stroke: $darkgray
                    height: 20px
                    width: 15px
                    stroke-width: 3px

        .content
            .name
                font-weight: bold
                font-size: $fontSizeM
                color: $black
                text-transform: capitalize
                background-color: $gray
                border-radius: $radiusS 5px 0 0
                padding: 2px 5px
                display: flex
                justify-content: space-between
                span
                    font-size: $fontSizeS
                    font-weight: normal
            .action-group
                display: grid
                grid-template-columns: 1fr 1fr

            .action
                display: flex
                //background: $white
                padding: 2px 5px
                border-radius: $radiusS
                align-items: center
                gap: 10px
                .type
                    display: inline-block
                    font-weight: bold
                    padding-right: 5px
                    color: $black
                .date
                    font-weight: normal
                    color: $darkgray
                    font-size: $fontSizeS
                .location
                    color: $black
                    font-size: $fontSizeS
                    font-weight: bold
                svg
                    background-color: $white
                    border-radius: $radiusS
                    padding: 5px
                    width: 28px
                    height: 28px
                    stroke: $darkgray

            .goods
                background: $white
                border: 5px solid $lightgray
                border-top: 0
                padding: 5px
                color: $darkgray
                h2,h3,h4,p, b, div
                    font-size: $fontSizeS
                    font-weight: normal
                    margin: 0
                    display: inline-block
                h2,h3,h4,b
                    color: $black
                    margin: 0 0 0 10px
                .goods
                    background: $lightgray
                    border: 5px solid $white
                    border-top: 0

.optimizer
    &__lanes
        display: flex
    &__trips
        flex: 1
        &__list
            margin-bottom: 10px
    &__trip
        border-bottom: 1px solid $lightgray
        border-left: 2px solid transparent
        padding: 15px
        &:hover
            cursor: pointer
            border-color: $primary
        &--active
            background: $gray
        &__vehicle
            padding-bottom: 5px
            span
                font-size: $fontSizeM
                background: $primary
                margin-left: 10px
                border-radius: $radiusS
                padding: 3px 8px
        &__route-information
            justify-content: space-between
            margin-top: 5px
            &__field
                flex: 1
    &__map
        flex: 1
        height: 100%
        min-height: 300px
    .lane
        flex: 1
        padding-right: 20px
        &--disabled
            opacity: 0.5
            user-select: none
            pointer-events: none

.planBoard,.watchTower, .planner
    display: block
    width: 100%
    &__table
        .react-tooltip
            opacity: 1 !important
            z-index: 99 !important
            color: white !important
        [data-cell-colidx="0"]
            overflow: visible !important
            border-right: none !important
        [data-cell-rowidx="0"]
            font-weight: bold
        .rg-custom-cell
            padding: 0px !important
        .planBoard__item, .watchTower__item
            height: 100%
            align-items: center
            border-bottom: none
    &__right-wrapper
        display: grid
        flex: 1
        align-self: start
    &__container
        position: relative
        max-height: calc(100vh - 110px)
        overflow: scroll
        &::before
            content: " "
            position: absolute
            right: 0
            top: 0
            height: 100%
            width: 20px
            background: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0), $white)
            background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), $white)
            background: linear-gradient(to right, rgba(255, 255, 255, 0), $white)
    &__items
        padding: 10px 0 0
    &__item
        display: inline-flex
        min-width: 100%
        flex-direction: row
        padding: 4px 0
        border-bottom: 1px solid $gray
        word-break: break-word
        &:hover
            cursor: pointer
            border-color: $primary
        &.child
            padding: 0
            border: none
        &--selected
            background: $lightgray
        &--selected &
            &__breakdown
                .trip-breakdown, .trips-breakdown
                    background: $white
        &--active &
            &__breakdown
                .down-button
                    svg
                        transform: rotateZ(180deg)
                        fill: $primary
                .trip-breakdown, .trips-breakdown
                    display: block

        &__breakdown
            width: 100%
            max-width: 95vw
            position: sticky
            left: 10px
            .down-button
                svg
                    margin: 0 auto
                    display: block
                    height: 7px
                    width: auto
                    fill: $black
                    +transition($s)
            .trip-breakdown, .trips-breakdown
                margin-top: 5px

                display: none
        &.second-level
            border: none
            min-width: auto
            .trip-breakdown
                min-width: 1000px
        .full-width
            min-width: calc(100% - 13px)
        .field
            width: 120px
            &--tiny
                width: 30px
            &--xsmall
                width: 50px
            &--small
                width: 100px
            &--median
                width: 180px
            &--big
                width: 240px
            &--bigxl
                width: 300px
            &--large
                width: 400px
            &:not(:last-child)
                padding-right: 8px
            &.icon
                width: 60px
                div
                    display: flex
                svg
                    width: 20px
                    height: 20px
                    padding: 1px
                    border-radius: $roundRadius
                    fill: $white
                    background: $primary
                    margin-right: 10px
            label
                font-weight: bold
                display: block
                font-size: $fontSizeS
                &:hover
                    cursor: inherit
        .status-icon
            width: 15px
            height: 15px
            svg
                width: 15px
                height: 15px
            &.on_route
                svg
                    fill: $green
                    stroke: $green
            &.off_route
                svg
                    fill: $red
                    stroke: $red
    &__status
        width: 5px
        height: 100%
        margin-right: 8px
        position: sticky
        left: 0
        border-radius: $radiusS
        &--empty, &--confirmed
            background: $yellow
        &--ok, &--in-transit, &--planned, &--completed
            background: $green
        &--off-route
            background: $purple
        &--too-late
            background: $red
        &--too-early
            background: $blue
        &--needs-action, &--partially_planned, &--partially_in_transit
            background: $orange
        &--requested
            background: $gray
        &--cancelled
            background: $black
    &__info
        display: flex
        &:hover
            cursor: pointer
    &__header, &__lastUpdated, &__live
        align-self: center
    &__header
        margin: 0
    &__lastUpdated
        margin-left: 5px
        font-size: $fontSizeS
    &__live
        border-radius: $radius
        font:
            size: $fontSizeS
            weight: bold
        background: $green
        color: $white
        padding: 3px 8px
        margin-left: 10px
        +animation(live-pulse 2s infinite)
    .trip-breakdown, .trips-breakdown
        background: $lightgray
    &__search-results
        min-height: 500px
        max-height: calc(100vh - 165px)
    &--standalone
        padding: 0 10px 400px 20px

    &__detail-box
        position: fixed
        bottom: 0
        left: 0
        width: 100%
        display: flex
        height: 400px
        background: $white
        z-index: 100
        box-shadow: 0px 0px 15px rgba(0,0,0,0.3)
        &__info, &__map
            height: 100%
        &__info
            width: 60%
        &__map
            width: 40%
        .tab-links-container
            background: $lightgray
            padding: 5px 0
            &.light
                background: $white
        .vehicle-detail
            height: 100%
            &__map
                height: 100%
                border-radius: 0
            &__box
                max-height: calc(100% - 50px)
                position: relative
            .chat
                height: 100%

        .combination-planner__attachments
            min-width: 100%
            max-width: 100%
    &.flex, &.trip
        display: flex

.watchTower
    &__status
        margin-right: 2px
    .actions .action .action-input
        width: 200px
    &__item__breakdown
        max-width: none
        .down-button
            max-width: 100vw

.route-planner
    .trip-view
        &__breakdown
            margin-top: 25px
        &__expenses
            margin-top: 25px
        &__map
            margin-top: 25px

.planner
    .calendar__content
        max-height: calc(100vh - 180px)

.map-planner
    min-height: 250px
    height: 100%
    width: 100%

.activity-
    box-shadow: $shadow
    background-color: $primary
    font-family: $secondaryHeaderFont
    z-index: 10
    margin: 0
    padding: 15px 0
    position: absolute
    transition: 0.5s

    .inline-tags
        display: none
        position: absolute
        top: -10px
        right: 10px
        .tag
            font-size: $fontSizeS
            padding: 2px 10px

    &.driving
        background-color: $green
    &.work
        background-color: $blue
    &.available
        background-color: $yellow
    &.rest
        background-color: $purple
    &.spacer
        box-shadow: none
        background-color: transparent
        z-index: -1

@media all and (max-width: 660px)
    .action, .action-input
        &.desktop-only
            display: none !important
