.header
  top: 0
  left: 0
  width: 100vw
  height: 50px
  z-index: 1000
  position: fixed
  line-height: 50px
  background: $white
  font-family: Montserrat, sans-serif
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.1)

  .info
    .section
      padding: 0
    .box
      cursor: default
      padding-top: 0 !important
      padding: 10px 15px 
    span, .dropdown-item
      display: block
      line-height: normal
    .content-header
      text-align: left
      .title
        font-size: $fontSizeL
        font-weight: bold
      .subtitle
        color: $darkgray
        font-size: $fontSizeM
      .close-button
        display: flex
        justify-content: flex-end
        svg 
          width: 12px
          height: 12px
        &:hover
          svg
            fill: $darkgray
    .content
      text-align: left
      padding: 15px 10px
      .support
        color: $black
        display: block
        cursor: pointer
        font-size: $fontSizeS
        text-align: right
        line-height: normal
        text-decoration: underline
        text-underline-offset: 1px
        transition: all 0.2s ease-out
        &:hover
          color: $primary
      .dropdown-item
        font-size: $fontSizeM
        cursor: pointer
        color: $darkgray
        line-height: 1.5
        width: fit-content
        transition: all 0.2s ease-out
        &:hover
          color: $primary
          background: $lightgray
      .heading
        color: $black
        font-size: $fontSizeL
        font-weight: bold
        &.disabled
          pointer-events: none
        &:hover
          background: transparent


  .logo-search-wrapper
    flex-wrap: nowrap

  &.banner
    height: 90px

  .announcement-banner
    top: 0
    left: 0
    width: 100vw
    height: 40px
    display: flex
    padding: 10px
    z-index: 1001
    position: relative
    align-items: center
    background: $primary
    justify-content: center
    .message
      color: $white
      font-size: $fontSizeM
      line-height: normal
    button
      color: $white
    button:hover
      color: $black

  &.clear
    background: none
    box-shadow: none
    border: none
    position: absolute

  & > *
    vertical-align: top

  .container
    height: fit-content

  &__search
    flex: 1
    max-width: 350px
    margin: 0 25px
    position: relative

    input
      font-family: Montserrat, sans-serif
      height: 40px
      padding: 3px 40px 3px 20px
      background: $lightgray
      border-radius: $radiusL
      font-size: $fontSizeM
      border: 1px solid $lightgray

      &:focus
        border-color: $primary

    &__icon
      cursor: pointer
      border-left: 5px solid $white

      svg
        position: absolute
        top: 50%
        right: 20px
        height: 15px
        width: 15px
        fill: $darkgray
        transform: translateY(-50%)

  &__search-result-container
    width: 100%
    background: $white
    border: 1px solid $gray
    border-radius: $radius
    border-top-right-radius: 0px
    border-top-left-radius: 0px
    border-top: 0
    position: absolute
    overflow: scroll
    top: 50px
    left: 0
    box-shadow: 0px 30px 53px -31px rgba(0, 0, 0, 0.2)

    &::before, &::after
      content: " "
      position: absolute
      height: 30px
      width: 100%

    &::before
      top: 0
      left: 0
      background: -webkit-linear-gradient(to bottom, $white, rgba(255, 255, 255, 0))
      background: -moz-linear-gradient(to bottom, $white, rgba(255, 255, 255, 0))
      background: linear-gradient(to bottom, $white, rgba(255, 255, 255, 0))

    &::after
      bottom: 0
      left: 0
      background: -webkit-linear-gradient(to top, $white, rgba(255, 255, 255, 0))
      background: -moz-linear-gradient(to top, $white, rgba(255, 255, 255, 0))
      background: linear-gradient(to top, $white, rgba(255, 255, 255, 0))

  &__search-result
    width: 100%
    max-height: calc(100vh - 50px)
    padding: 20px
    line-height: normal

    .result-group
      margin-top: 20px

    .result
      display: flex
      padding: 8px 0
      border-bottom: 1px solid $gray
      +transition($s)
      &:last-child
        border-bottom: 1px solid transparent
      &:hover
        cursor: pointer
        border-color: $primary
      div
        flex: 1
        min-width: 120px
        &.one
          flex: 1
          min-width: 120px
        &.two
          flex: 2
          min-width: 180px
        &.three
          flex: 3
          min-width: 240px
        &.four
          flex: 4
          min-width: 300px
        &.five
          flex: 5
          min-width: 360px

        &:not(:first-child)
          margin-left: 16px

  .listbutton
    //width: 75px
    height: 50px
    line-height: 50px
    color: $primary
    display: flex !important
    justify-content: center
    position: relative
    margin-left: 7px
    svg
      width: 25px
      height: 65px
      line-height: 65px
      display: block
      fill: $primary
    &:hover
      cursor: pointer
      color: $secondary
      svg
        fill: $secondary

  .logo
    width: auto
    height: 40px
    margin: 5px 0
    max-width: 180px
    object-fit: contain
    svg
      width: 100%
      height: 49px
    &.middle
      margin: 5px auto

  img
    display: block
    position: relative
    width: auto
    height: 65px
    &.middle
      margin: 0 auto
    &.left
      float: left

  .create
    margin-right: 20px
  .link-menu
    display: flex
    a
      padding: 0 10px
      &.active
        background-color: $primary
        color: $white
  .menu
    display: flex
    justify-content: space-around
    align-items: center
    position: relative
    height: 50px
    gap: 5px
    margin-left: 20px

    .icon
      height: 40px
      width: 40px
      border-radius: $radius
      text-align: center
      cursor: pointer
      overflow: hidden

      svg
        width: 22px
        height: 25px
        margin-bottom: 7px
        fill: $primary

      &:hover
        background-color: $lightgray

      .button
        height: auto
        display: block
        position: relative
        text-align: center

      svg
        fill: $primary
        width: 25px
        height: 25px
        display: block
        margin: 7.5px auto

      &.notifications.new, &.messages.new
        &::before
          content: attr(data-content)
          position: absolute
          min-width: 10px
          width: auto
          height: 15px
          background: $secondary
          color: white
          bottom: 55%
          right: 5px
          line-height: 15px
          font:
            size: $fontSizeS
            weight: 600
          z-index: 100
          text-align: center
          pointer-events: none
          border-radius: $radius
          padding: 1px 3px
          +transition($s)

      &.messages.new
        &::before
          right: 0px

    .messages, .notifications, .info
      .box
        display: none
        position: absolute
        width: 400px
        padding-top: 50px
        top: 65px
        margin-left: -365px
        background: $white
        box-shadow: 0px 10px 33px -1px rgba(0, 0, 0, 0.15)
        border-radius: $radius
        border-top-right-radius: 0px
        border: 1px solid $gray
        z-index: 1200
        color: $black
        animation: $s pop-out

        &.show
          animation: 0.1s pop-in
          display: block

        &::after
          bottom: 100%
          border: solid transparent
          content: " "
          height: 0
          width: 0
          position: absolute
          pointer-events: none
          border-bottom-color: $white
          border-width: 15px
          right: 0px

        .n_header
          text-align: left
          padding: 10px 10px 0
          height: 50px
          line-height: 30px
          position: absolute
          top: 0
          left: 0
          width: 100%
          box-sizing: border-box
          font:
            size: $fontSizeL

          .actions
            float: right

            a
              margin-left: 10px

          a
            font:
              size: $fontSizeM

        .item-list
          max-height: 450px
          overflow-y: scroll

        .nothing
          margin: 10px 0

        .more
          width: 100%
          height: 25px
          line-height: 25px
          font-size: $fontSizeM
          text-align: center

          &:hover
            text-decoration: underline

        .message
          width: 100%
          border-bottom: 1px solid $gray
          color: $black
          text-align: left
          box-sizing: border-box
          padding: 10px
          position: relative
          display: flex

          &.new
            background: $gray

          &:hover
            background: $lightgray

            &::after
              position: absolute
              content: ""
              height: 100%
              width: 2px
              background: $primary
              left: 0
              top: 0

          .left
            flex: 2
            line-height: 15px
            font-size: $fontSizeM
            min-height: 0px
            padding: 0

            h1
              display: block
              height: 25px
              line-height: 25px
              margin: 0px
              padding-top: 5px
              font:
                size: $fontSizeL
              text-align: left

              span
                position: relative

              .unread
                font-size: $fontSizeS
                position: absolute
                left: 105%
                top: 5px
                background: $primary
                color: $white
                border-radius: $radius
                min-width: 16px
                width: auto
                height: 16px
                display: inline-block
                text-align: center
                line-height: 16px
                padding: 0 3px

              .date
                font:
                  size: $fontSizeS
                  style: italic
                  weight: normal
                float: right

            p
              margin: 6px 0
              color: $darkgray

          img , svg, .avatar
              width: 55px
              height: 55px
              border-radius: $roundRadius
              align-self: center
              justify-content: flex-end
              filter: none
              margin: 0
              object-fit: cover
          img, svg, .avatar
            margin-right: 10px

  .logout, .create
    width: auto
    height: 35px
    margin: 15px 0

  &.full
    +linear-background()

    .listbutton
      color: $lightgray

      &:hover
        color: $secondary

    .menu .icon
      color: $lightgray

    .logout, .create
      background: $lightgray
      border: 2px solid $lightgray
      color: $primary

      &:hover
        background: none
        color: $lightgray

  // ------------- aside menu ------------------ //
  aside
    width: 450px
    max-width: 100%
    height: 100vh
    position: absolute
    transform: translateX(100%)
    background: $white
    color: $black
    z-index: 1200
    top: 0
    right: 0
    opacity: 0
    line-height: 1
    box-shadow: 0 10px 33px -1px rgba(0, 0, 0, 0.2)
    transition: $s
    padding-right: 1px
    &.show
      animation: $s menu-slide-in
      transform: translateX(0%)
      opacity: 1
    .aside-content-box
      padding: 10px 0
      max-height: calc(100vh - 10px)
      width: 100%
      a
        color: $black
      hr
        margin: 10px 0
        border: none
        border-top: 2px dashed $lightgray

      .aside-account
        width: 100%
        line-height: 25px
        height: 50px
        padding: 0 15px
        box-sizing: border-box
        display: flex
        align-items: center
        justify-content: space-between

        .account-logo
          width: 45px
          height: 45px
          border-radius: $roundRadius
          background-color: $lightgray
          box-shadow: $shadow
          img
            width: 45px
            height: 45px
            object-fit: cover
            border-radius: $roundRadius

        .account-name
          text-align: left
          color: $primary
          font-weight: bold
          font-size: $fontSizeL
          margin-bottom: -5px

        .account-email
          text-align: left

        .close-button
          svg
            width: 15px
            height: 15px
            stroke-width: 5px
      .aside-create-new
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        gap: 15px
        padding: 15px

        .item
          background: $lightgray
          border-radius: $radiusS
          height: 65px
          line-height: 0
          display: flex
          flex-direction: column
          align-items: center
          gap: 10px
          justify-content: center

          &:hover
            background: $primary
            cursor: pointer

            svg, .item-label
              stroke: $white
              color: $white

          svg
            height: 17px
            width: 17px
            stroke: $darkgray
            stroke-width: 4px

          .item-label
            font-size: $fontSizeM
            height: 10px
            color: $darkgray

      .aside-list-item
        border-left: 3px solid transparent
        display: flex
        gap: 10px
        align-items: center
        padding: 7px 15px
        &:hover
          background-color: $lightgray
          border-left: 3px solid $primary
          cursor: pointer
        svg
          height: 25px
          width: 25px
          border-radius: $radiusS
          padding: 5px
          stroke: $darkgray
          background-color: $lightgray

.help-view
  position: absolute
  top: 65px
  left: 50%
  transform: translateX(-50%)
  margin: 0 auto
  background: $white
  line-height: 45px
  max-height: calc(100% - 130px)
  overflow: scroll
  border-radius: $radius
  .one
    padding: 25px
  h1
    padding: 25px
  hr
    margin: 0
    border: none
    border-top: 1px solid $gray
  &.container
    height: auto

.menu, .help-view
  .key
    margin-right: 4px
    padding: 5px
    background: $lightgray

.create-order-dropdown
  margin-top: 5px
  &:hover
    .active-item .chevron-down
      margin-top: 5px
      transform: rotate(0deg)
    .items
      pointer-events: all
      opacity: 1 !important

  .active-item
    height: 40px
    display: flex
    color: $white
    cursor: pointer
    padding: 0 15px
    line-height: 40px
    align-items: center
    border-radius: $radiusL
    background: $primary
    justify-content: space-between

    .chevron-down
      margin-top: -5px
      margin-left: 5px
      transform: rotate(180deg)

      svg
        width: 15px
        height: 15px
        stroke: $white
        stroke-width: 5px

    .plus-icon
      width: 22px
      height: 22px
      display: flex
      overflow: hidden
      margin-right: 5px
      border-radius: $roundRadius
      align-items: center
      justify-content: center
      background-color: $white

      svg
        width: 10px
        height: 10px
        stroke: $primary
        stroke-width: 5px

  .items
    padding: 10px
    margin-top: 5px
    overflow: hidden
    border-radius: $radius
    background-color: $white

    .item
      cursor: pointer
      line-height: 40px
      margin-bottom: 5px
      border-radius: $radiusS
      height: 40px !important
      padding: 0 10px !important
      font-weight: normal !important

      &:hover
        background-color: $lightgray

    .item-active
      background-color: $lightgray

.bottom-menu
  width: 100%
  height: 42px
  position: fixed
  bottom: 0
  left: 0
  background: $white
  z-index: 1000
  display: none
  -webkit-transform: translateZ(0)
  transform: translateZ(0)
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.4)

  .section
    flex: 1
    height: 42px
    position: relative
    text-align: center
    border-top: 2px solid $white

    &.active
      border-top-color: $primary

      svg
        fill: $primary

    svg
      height: 20px
      width: 20px
      display: block
      fill: $darkgray
      margin: 11px auto

@media all and (max-width: 1190px)
  .header
    .container .wrapper
      display: flex

@media all and (max-width: 768px)
  .info
    .box
      transform: translateX(12%)
  .header
    &__search
      display: none

@media all and (max-width: 660px)
  body
    padding-bottom: 50px
  .header
    .announcement-banner .message
      font-size: $fontSizeM
    &::before
      content: " "
      position: absolute
      right: initial
      top: 0
      right: 0
      z-index: 1001
      width: 100vw
      height: 100vh
      pointer-events: none
      background: rgba(0, 0, 0, 0.9)
      opacity: 0

    &.show
      &::before
        opacity: 1

    &__search
      display: none

    .bottom-menu
      display: flex

    .menu
      .home, .transport-orders, .messages, .notifications
        display: none

    .create
      padding: 0 25px 0 15px
      font-size: $fontSizeM

    .dashboard
      display: none

    .listbutton
      width: 30px

      &::before
        text-align: center

    .container .wrapper
      display: flex

    .logo
      margin-left: 0
      max-width: 120px

    .dropdown
      margin-left: 0

      .item
        img
          width: 45px
          height: 45px

        .name
          display: none

@media all and (max-width: 425px)
  .header aside
    width: 100vw

  .create-order-dropdown
    padding: 0
    margin-top: 19px
    font-size: $fontSizeM !important
    min-width: 150px !important

  .create-order-dropdown .active-item
    height: 35px
    padding: 0 8px
    line-height: 35px

  .header .menu
    margin-left: 5px

  .header .menu .icon svg
    width: 20px
    height: 20px
